import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressiveImg from "../components/ProgressiveImg";
import "./Article.css";
import NoPage from "./NoPage";

export default function Article({ key }) {
  const { t } = useTranslation();
  const [article, setArticle] = useState(null);
  const slug = key ?? useParams()?.slug;

  useEffect(() => {
    fetch("/api/articles?slug=" + slug + "&lang=" + i18next.resolvedLanguage)
      .then((response) => response.json())
      .then((data) => setArticle(data))
      .catch(() => {});
  }, []);

  if (!article) {
    return <></>;
  } else if (!("title" in article) || !("content" in article)) {
    return <NoPage />;
  }

  return (
    <>
      <Header />

      <main className="article">
        {article.image && article.image !== "" ? (
          <ProgressiveImg className="cover" src={article.image} alt={article.title} />
        ) : (
          <></>
        )}
        <article>
          <div>
            <span className="subtitle">{article.subtitle ?? ""}</span>
            <h1>{article.title}</h1>
            <span>{article.author ? t("article.by") + " " + article.author : ""}</span>
          </div>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            disallowedElements={["h1", "h4", "h5", "h6"]}
          >
            {article.content}
          </ReactMarkdown>
        </article>
      </main>

      <Footer />
    </>
  );
}
