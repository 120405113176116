import { useEffect, useState } from "react";

import "./ProgressiveImg.css";

export default function ProgressiveImg({
  src,
  placeholderSrc = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
  ...props
}) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc);
  const loading = imgSrc === placeholderSrc;

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImgSrc(src);
  }, [src]);

  return (
    <img
      {...{ src: imgSrc, ...props }}
      className={`${props.className ?? ""}${loading ? " loading" : ""}`}
      alt={props.alt ?? ""}
      draggable={false}
    />
  );
}
