import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressiveImg from "../components/ProgressiveImg";
import "./About.css";

export default function About() {
  const { t } = useTranslation();

  return (
    <>
      <Header />

      <main className="about">
        <div className="image">
          <ProgressiveImg src="/images/about.webp" alt="" />
        </div>
        <article>
          <h1>{t("about.title")}</h1>
          {t("about.content")
            .split("\n")
            .map((text, index) => (
              <p key={index}>{text}</p>
            ))}
        </article>
      </main>

      <Footer />
    </>
  );
}
