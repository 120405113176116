import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PhotoAlbum from "react-photo-album";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Gallery.css";

const categories = [
  { name: "gallery.landscape", tag: "landscape" },
  { name: "gallery.cities", tag: "city" },
  { name: "gallery.animals", tag: "animal" },
  { name: "gallery.flowers", tag: "flower" },
  { name: "gallery.sunset", tag: "sunset" },
  { name: "gallery.deepSky", tag: "deep-sky" },
];

const sorts = [
  { name: "gallery.sortDefault", sort: "default", funct: (a, b) => 0 },
  {
    name: "gallery.sortTitle",
    sort: "title",
    funct: (a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0),
  },
  {
    name: "gallery.sortDate",
    sort: "date",
    funct: (a, b) => (a.date && b.date ? b.date - a.date : 0),
  },
  {
    name: "gallery.sortLocation",
    sort: "location",
    funct: (a, b) =>
      a.location && b.location && a.location !== b.location
        ? a.location < b.location
          ? -1
          : a.location > b.location
          ? 1
          : 0
        : b.date - a.date,
  },
];

export default function Gallery({ tag }) {
  const { t } = useTranslation();
  const category = tag ?? useParams()?.category ?? "";

  const [photos, setPhotos] = useState([]);
  const [selection, setSelection] = useState(photos);
  const [filter, setFilter] = useState(category);
  const [search, setSearch] = useState();
  const [sort, setSort] = useState();
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    fetch("/api/photos?lang=" + i18next.resolvedLanguage)
      .then((response) => response.json())
      .then((data) => setPhotos(data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    setSelection(
      photos
        .filter(({ tags }) => !filter || filter === "" || tags.includes(filter))
        .filter(
          ({ title, tags }) =>
            !search ||
            search === "" ||
            title.toLowerCase().includes(search.toLowerCase()) ||
            tags.filter((tag) =>
              t("gallery.tag." + tag, tag)
                .toLowerCase()
                .includes(search.toLowerCase())
            ).length
        )
        .toSorted(!sort ? (a, b) => 0 : sort.funct)
    );
  }, [photos, filter, search, sort]);

  return (
    <>
      <Header />

      <main className="gallery">
        <div>
          <div className="bar">
            <h1>{t("gallery.title")}</h1>
            <div className="filter">
              <div
                className={!filter || filter === "" ? "active tag" : "tag"}
                tabIndex={0}
                onClick={() => setFilter("")}
              >
                {t("gallery.all")}
              </div>
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={filter === category.tag ? "active tag" : "tag"}
                  tabIndex={0}
                  onClick={() => setFilter(category.tag)}
                >
                  {t(category.name)}
                </div>
              ))}
              <div className="filter">
                <div className="search">
                  <input
                    id="search"
                    placeholder={t("gallery.search")}
                    type="text"
                    onChange={(e) => setSearch(e.target?.value)}
                  ></input>
                  <div className="option tag" tabIndex={0}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M784-120L532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56zM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400z" />
                    </svg>
                  </div>
                </div>
                <div className="sort">
                  <div className="hidden dropdown" id="sort" tabIndex={0}>
                    <span
                      onClick={(e) => {
                        const contents =
                          e.target?.parentElement?.getElementsByClassName("dropdown-content");
                        for (let content of contents) {
                          content.style.display =
                            content.style.display === "block" ? "none" : "block";
                        }
                      }}
                    >
                      {!sort ? t("gallery.sortDefault") : t(sort.name)}
                    </span>
                    <div className="dropdown-content">
                      {sorts.map((entry, index) => (
                        <div
                          key={index}
                          tabIndex={0}
                          onClick={(e) => {
                            setSort(entry);
                            const content = e.target?.parentElement;
                            content.style.display =
                              content.style.display === "block" ? "none" : "block";
                          }}
                        >
                          {t(entry.name)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="option tag"
                    tabIndex={0}
                    onClick={(e) => {
                      const sort = document.getElementById("sort");
                      const contents = sort?.getElementsByClassName("dropdown-content");
                      sort?.classList.toggle("hidden");
                      for (let content of contents) {
                        content.style.display = "block";
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                    >
                      <path d="M120-240v-80h240v80H120zm0-200v-80h480v80H120zm0-200v-80h720v80H120z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PhotoAlbum
            layout="rows"
            photos={selection}
            renderPhoto={GalleryImage}
            spacing={12}
            targetRowHeight={(containerWidth) => {
              if (containerWidth + 40 <= 300) return 1.5 * containerWidth;
              if (containerWidth + 40 <= 600) return containerWidth;
              if (containerWidth + 40 <= 1200) return containerWidth / 2.5;
              if (containerWidth + 40 <= 2400) return containerWidth / 5;
              else return containerWidth / 7.5;
            }}
            onClick={({ index }) => setIndex(index)}
            sizes={{ size: "calc(100vw - 40px)" }}
          />
          <Lightbox
            slides={selection}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            styles={{
              slide: { padding: 0 },
            }}
            plugins={[Captions, Fullscreen, Zoom, Download, Slideshow]}
          />
        </div>
      </main>

      <Footer />
    </>
  );
}

function GalleryImage({ photo, imageProps, wrapperStyle }) {
  return (
    <div className="image" style={{ ...wrapperStyle }} onClick={imageProps.onClick}>
      <div className="loading">
        <LazyLoadImage
          src={imageProps.src}
          alt={photo.title}
          effect="opacity"
          srcSet={imageProps.srcSet}
          sizes={imageProps.sizes}
          draggable={false}
        />
        <div className="caption">
          <span>{photo.title}</span>
        </div>
      </div>
    </div>
  );
}
