import { useTranslation } from "react-i18next";

import Header from "../components/Header";
import ProgressiveImg from "../components/ProgressiveImg";
import "./NoPage.css";

export default function NoPage() {
  const { t } = useTranslation();

  return (
    <>
      <Header transparent />

      <div className="nopage">
        <ProgressiveImg src="/images/page404.webp" alt="" />
        <div className="title">
          <h1>{t("noPage.title")}</h1>
          <p>{t("noPage.message")}</p>
        </div>
      </div>
    </>
  );
}
