import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./i18n";
import "./index.css";
import About from "./pages/About";
import Article from "./pages/Article";
import Equipment from "./pages/Equipment";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import NoPage from "./pages/NoPage";
import Tips from "./pages/Tips";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route index element={<Home />} />

      <Route path="gallery" element={<Gallery />} />
      <Route path="gallery/:category" element={<Gallery />} />
      <Route path="fotos" element={<Gallery />} />
      <Route path="photos" element={<Gallery />} />
      <Route path="astro" element={<Gallery tag="night" />} />

      <Route path="equipment" element={<Equipment />} />

      <Route path="tips" element={<Tips />} />
      <Route path="tips/:category" element={<Tips />} />
      <Route path="tips/article/:slug" element={<Article />} />

      <Route path="about" element={<About />} />
      <Route path="imprint" element={<Imprint />} />

      <Route path="*" element={<NoPage />} />
    </Routes>
  </BrowserRouter>
);
