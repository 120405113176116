import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressiveImg from "../components/ProgressiveImg";
import "./Tips.css";

const categories = [
  { name: "tips.photography", tag: "photography" },
  { name: "tips.editing", tag: "editing" },
  { name: "tips.location", tag: "location" },
  { name: "tips.equipment", tag: "equipment" },
];

export default function Tips({ tag }) {
  const { t } = useTranslation();
  const category = tag ?? useParams()?.category ?? "";

  const [articles, setArticles] = useState([]);
  const [filter, setFilter] = useState(category);

  useEffect(() => {
    fetch("/api/articles?lang=" + i18next.resolvedLanguage)
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch(() => {});
  }, []);

  return (
    <>
      <Header />
      <main className="tips">
        <article>
          <div className="bar">
            <h1>{t("tips.title")}</h1>
            <div className="filter">
              <div
                className={!filter || filter === "" ? "active" : ""}
                tabIndex={0}
                onClick={() => setFilter("")}
              >
                {t("tips.all")}
              </div>
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={filter === category.tag ? "active" : ""}
                  tabIndex={0}
                  onClick={() => setFilter(category.tag)}
                >
                  {t(category.name)}
                </div>
              ))}
            </div>
          </div>
          <div className="grid">
            {articles
              .filter(({ tags }) => !filter || filter === "" || tags.includes(filter))
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((article, index) => (
                <TipsEntry key={index} article={article} />
              ))}
          </div>
        </article>
      </main>
      <Footer />
    </>
  );
}

function TipsEntry({ article }) {
  const { t } = useTranslation();

  return (
    <Link to={"/tips/article/" + article.slug}>
      <div className="card">
        <div className="image">
          <ProgressiveImg src={article.image} alt={article.title} />
        </div>
        <div className="text">
          <div>
            <span className="subtitle">{article.subtitle}</span>
            <h2>{article.title}</h2>
            <span>{t("article.by") + " " + article.author}</span>
          </div>
          <p>{article.description}</p>
        </div>
      </div>
    </Link>
  );
}
