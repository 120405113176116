import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Imprint.css";

export default function Imprint() {
  const { t } = useTranslation();

  return (
    <>
      <Header />

      <main className="imprint">
        <article>
          <h1>{t("imprint.title")}</h1>
          <p>{t("imprint.subtitle")}</p>
          <p>
            {t("imprint.name")}
            {t("imprint.name") !== "" ? <br /> : <></>}
            {t("imprint.street")}
            {t("imprint.street") !== "" ? <br /> : <></>}
            {t("imprint.city")}
            {t("imprint.city") !== "" ? <br /> : <></>}
            {t("imprint.telNumber") !== "" ? (
              <a href={"tel:" + t("imprint.telNumber")}>
                {t("imprint.telephone") + t("imprint.telNumber")}
              </a>
            ) : (
              <></>
            )}
            {t("imprint.telNumber") !== "" ? <br /> : <></>}
            {t("imprint.mailAddress") !== "" ? (
              <a href={"mailto:" + t("imprint.mailAddress")}>
                {t("imprint.mail") + t("imprint.mailAddress")}
              </a>
            ) : (
              <></>
            )}
          </p>
          <h2>{t("imprint.contentLiabilityTitle")}</h2>
          <p>{t("imprint.contentLiability")}</p>
          <h2>{t("imprint.linkLiabilityTitle")}</h2>
          <p>{t("imprint.linkLiability")}</p>
          <h2>{t("imprint.copyrightTitle")}</h2>
          <p>{t("imprint.copyright")}</p>
        </article>
      </main>

      <Footer />
    </>
  );
}
