import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ProgressiveImg from "../components/ProgressiveImg";
import "./Equipment.css";

export default function Equipment() {
  const { t } = useTranslation();
  const [equipments, setEquipments] = useState([]);
  const [categories, setCategories] = useState(["camera"]);

  useEffect(() => {
    fetch("/api/equipment?lang=" + i18next.resolvedLanguage)
      .then((response) => response.json())
      .then((data) => {
        setEquipments(data);
        setCategories([...new Set(data.map((data) => data.type))]);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Header />

      <main className="equipment">
        {categories.map((category, index) => (
          <div key={index}>
            <div className="bar">
              <h1>{t("equipment." + category, category)}</h1>
            </div>
            <div className="grid">
              {equipments
                .filter(({ type }) => type === category)
                .map((equipment, index) => (
                  <EquipmentCard key={index} equipment={equipment} />
                ))}
            </div>
          </div>
        ))}
      </main>

      <Footer />
    </>
  );
}

function EquipmentCard({ equipment }) {
  return (
    <div className="card">
      <div className="image">
        <ProgressiveImg src={equipment.image} alt={equipment.name} />
      </div>
      <div className="text">
        <span>{equipment.manufacturer ?? ""}</span>
        <h2>{equipment.name}</h2>
        <p>{equipment.description}</p>
      </div>
    </div>
  );
}
